import { builderData, APOData, templateJSON } from "./BuilderProps";
import {
  findSuperParentWithClass,
  updateHandlebarsTemp,
  mouserOver_method,
} from "./CommonMethod";
import { APO__DataUpdate } from "./OnboardingData";
import { GetAPI_deleteAPI_method } from "./Api";
import { onFlyProps_remove } from "./PropertySetting";
import { initdatepicker } from "./Calendar";
import { appointmentLayout } from "./ServiceFilterHtml";
import { ServiceStyle } from "./ShowModuleStyle";
import { find } from 'lodash-es';

(async function () {
  // Service filter change handler
  $(".serviceStep").change(async function () {
    const { APOtype } = APOData;
    const WName = "Service";
    const templateName = this.options[this.selectedIndex].dataset.template;
    APOData.APO_selectedData.serviceActiveStep = templateName;

    const parentElement = findSuperParentWithClass(builderData.editWidget, WName);

    // Toggle visibility based on selected step
    document.querySelector(".ServiceFilter").style.display =
      this.value !== "1" ? "none" : "";
    try {
      switch (templateName) {
        case "servicelist":
          $("#datepicker").datepicker("destroy");
          builderData.widgetName = WName;
          ServiceStyle(WName);
          appointmentLayout(APOData[APOtype]);
          break;
        case "stafflist":
        case "datetime":
          onFlyProps_remove();
          await Staff_Calendar(templateName, WName);
          break;
        case "userdetails":
          if (
            templateName === "userdetails" ||
            (templateName === "datetime" && APOtype === "Event")
          ) {
            onFlyProps_remove();
            await APO__orderdone(templateName, WName);
          }
          break;
        default:
          console.warn(`Unknown template name: ${templateName}`);
      }
    } catch (error) {
      console.error("Error handling service step change:", error);
    }
  });
})();

export const get__ServiceEventClass = async () => {
  try {

    //update APOtype if Appointment
    if (APOData.APOtype === "Appointment") {
      APOData.APOtype = "Service";
    }

    const { APOtype, onboardingData } = APOData;
    let data = [];

    // Fetch data if the type is not "Service" and the data is not already loaded
    switch (APOtype) {
      case "Service":
        data = onboardingData.serviceMenusList;
        break;
      // case "Event":
      //   // create new array with new perms for Event from data 
      //   const defaultHoursList = [];
      //   onboardingData.defaultHoursList.forEach(item => {
      //     if (item.isDisabled) {
      //       defaultHoursList.push({
      //         id: item.time,
      //         duration: item.time,
      //         isDisabled: item.isDisabled,
      //         title: item.name,
      //       });
      //     }
      //   });
      //   data = defaultHoursList;
        //break;
        case "Class":
        case "Event":
        const response = await GetAPI_deleteAPI_method(APOtype + (APOtype === "Class" ? "es" : "s"), "GET");
        data = response.data;
    }

    // Update and return the data for the given APOtype
    APOData[APOtype] = await APO__DataUpdate(data, APOtype);
    return APOData[APOtype];

  } catch (error) {
    console.error("Error fetching data:", error.message);
    return [];
  }
};

const Staff_Calendar = async (templateName, WName) => {
  try {
    // Ensure selected appointment data is available
    await previewSelectedAPO_data(templateName);

    // Destructure necessary data from APOData
    const { APOtype, onboardingData, APO_selectedData } = APOData;
    // const { appointmentJSON } = APO_selectedData;

    // Select the element where the template will be appended
    const appendEle = document.querySelector(`.${WName} .APO_booking_content`);
    const template = document.getElementById(`template_${templateName}`).innerHTML;

    // Prepare data for the Handlebars template
    const templateData = {
      ...APO_selectedData,
      stafflist: onboardingData.staffMembersList,
      SkipTeamMembers: APOtype === "Event" ? true : false
      // staffRule: false
    };

    // Compile the Handlebars template and update the UI
    await updateHandlebarsTemp(appendEle, template, templateData);

    // Show class on row and widget hover
    mouserOver_method();

    // Initialize datepicker if the template requires it
    if (["datetime"].includes(templateName)) {
      initdatepicker();
    }
  } catch (error) {
    console.error("Error in processing staff list and datetime template:", error);
  }
};

const previewSelectedAPO_data = async (templateName) => {
  const { onboardingData, APOtype, APO_selectedData } = APOData;
  const apptId = APOData[APOtype][0].id;
  APO_selectedData.appointmentJSON = APOData[APOtype]?.find(item => item.id == apptId); // Find the data by ID;

  if (["datetime"].includes(templateName)) {
    const staffID = onboardingData.staffMembersList[0].id;
    const staffJSON = find(onboardingData.staffMembersList, { id: staffID });
    APO_selectedData.staffJSON = staffJSON;
  }
};

const APO__orderdone = async (templateName, WName) => {
  try {
    await previewSelectedAPO_data();
    const { APO_selectedData, CountriesList, onboardingData } = APOData;
    const { Signup } = templateJSON.StyleObj;

    if (!CountriesList.length) {
      const response = await GetAPI_deleteAPI_method("Countries", "GET");
      Object.assign(CountriesList, response.data);
    }

    const appendEle = document.querySelector(`.${WName} .APO_booking_content`);
    const template = document.getElementById(`template_${templateName}`).innerHTML;

    const orderDetail = {
      ...APO_selectedData,
      CountriesList,
      form: Signup.form,
      UserCountries: onboardingData.userCountry
    };

    await updateHandlebarsTemp(appendEle, template, orderDetail); // Compile Handlebars template

    $(".DropwithSearch").customSelect({ search: true }); // Initialize custom select
    mouserOver_method(); // Show class on row and widget hover
  } catch (error) {
    console.error("Error in Customer Detail:", error);
  }
};

