import { APOData, builderData } from "./BuilderProps";
import { GetAPI_deleteAPI_method } from "./Api";
import { appointmentLayout, appointmentTypeList } from "./ServiceFilterHtml";
import { templateSetting } from "./TemplateSettings";
// import { bookedAppointmentsList } from "./BookedAppointmentsList";
import moment from "moment";
import "moment-timezone";

export const OnboardingData = async () => {
  try {
    const response = await GetAPI_deleteAPI_method("Onboarding", "GET");
    const { data } = response;
    APOData.onboardingData = data; // Service list
    APOData.businessTimeZone = data.businessAddressList[0].timeZone; //globally set business time zone
    moment.tz.setDefault(APOData.businessTimeZone);

    await appointmentTypeList(data);
    await templateSetting(); // Template settings

    // Layout and template settings
    await appointmentLayout();
  } catch (error) {
    console.error("Error during onboarding data processing:", error);
  }
};

export const APO__DataUpdate = async (data, type) => {
  try {
    const { userCurrencySymbol } = APOData.onboardingData;
    return data.map((item) => ({
      ...item,
      currencySymbol: userCurrencySymbol,
      APOtype: type,
    }));
  } catch (error) {
    console.error("Error updating APO data:", error);
    throw error; // Re-throw the error if necessary
  }
};
