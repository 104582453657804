import { getUrlParams } from "./CommonMethod"; 

const { APIURL } = process.env;

export const PostAPI_PutAPI_method = async (url, method, post_data) => {
  try {
    const token = `Bearer ${await getUrlParams("tk")}`;
    const response = await fetch(`${APIURL}${url}`, {
      withCredentials: true,
      method: method,
      body: JSON.stringify(post_data),
      headers: {
        "content-type": "application/json",
        Authorization: token ? token : "",
      },
    });
    if (response.status === 200) {
      const data = await response.json();
      return { data, status: response.status };
    } else {
      return { data: null, status: response.status };
    }
  } catch (err) {
    console.warn(err);
  }
};
export const GetAPI_deleteAPI_method = async (url, method, target) => {
  try {
    const token = `Bearer ${await getUrlParams("tk")}`;
    const response =  await fetch(`${APIURL}${url}`, {
      withCredentials: true,
      method: method,
      headers: {
        Authorization: token,
        target: target == undefined ? "" : target,
      },
    });
    if (response.status === 200) {
      const data = await response.json();
      return { data, status: response.status };
    } else {
      return { data: null, status: response.status };
    }
  } catch (err) {
    console.warn(err);
  }
};
