import { builderData, templateJSON } from "./BuilderProps";
import { updateHandlebarsTemp } from "./CommonMethod";
export const SignupformFields = async () => {
  const { Signup } = templateJSON.StyleObj;
  const html = `<div class="custom-accordion accordion-setting onFly">
    <h3 class="setting-title">Form Elements</h3>
    <div class="setting-panel">
        <label class="property-label">Choose signup feild's.</label>
        <div class="signuPropAccordion pb-5 flex flex-col gap-2">
            ${Signup.form.fields
              .map(
                (items) => `
                    <div data-fid="${items.id}" class="fieldsprops_List">
                        <div class="formAcrdnHandle items-center gap-2">
                            <div class="formsortHandle cursor-move pl-5 pr-2 py-3"><i class="fa-solid fa-bars"></i></div>
                            <h3 class="formAcrdn-title">${items.label.text}</h3>
                        </div>
                        <div class="formprop-panel">
                            <div class="flex flex-col gap-5">
                                <div class="signFprop__ flex flex-col gap-2 ${!items.label.show && `hidden`}" data-fid="${items.id}">
                                    <label class="property-label mb-0">Label</label>
                                    <input class="input-field __LabelVal" data-fid="${items.id}" type="text" placeholder="Enter field label" value="${items.label.text}" onchange="SignFprop_value(event, this)">
                                </div>
                                ${
                                  !!items.attributes.placeholder
                                    ? `<div class="signFprop__ flex flex-col gap-2" data-fid="${items.id}">
                                            <label class="property-label mb-0">Placeholder Text</label>
                                            <input class="input-field __placeholderVal" data-fid="${items.id}" type="text" placeholder="(Optional)" value="${items.attributes.placeholder}" onchange="SignFprop_value(event, this)">
                                        </div>`
                                    : ``
                                }
                                <div class="signFprop__ flex flex-col gap-2" data-fid="${items.id}">
                                    <div class="custom-checkRadio switch-checbox relative flex items-center gap-2">
                                        <input type="checkbox" data-fid="${
                                          items.id
                                        }" class="__requiredVal h-5 w-5" ${items.label.show ? "checked" : ""} onchange="SignFlabel_showHide(event, this)">
                                        <label class="property-label m-0">Show/Hide label</label>
                                    </div>
                                </div>
                                <div class="signFprop__ flex flex-col gap-2" data-fid="${items.id}">
                                    <div class="custom-checkRadio switch-checbox relative flex items-center gap-2">
                                        <input type="checkbox" data-fid="${
                                          items.id
                                        }" class="__requiredVal h-5 w-5" ${items.attributes.required ? "checked" : ""} onchange="SignFprop_value(event, this)">
                                        <label class="property-label m-0">This field is required</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>              
                `
              )
              .join("")} 
            </div>
        </div>
    </div>`;

    const Insert = document.querySelector('.insertAccordion-MProp');
    Insert.insertAdjacentHTML("beforeend", html);
    await signup_filedAccordion(Signup);
    $(".custom-accordion").accordion({collapsible: true}); // Initialize accordion 
    
};

const signup_filedAccordion = async (Signup) => {
    $(".signuPropAccordion").accordion({
        active: false,
        collapsible: true,
        header: ".formAcrdnHandle",
        heightStyle: "content",
    }).sortable({ 
        axis: "y",
        handle: ".formsortHandle",
        stop: async function( event, ui ) {
            // so trigger focusout handlers to remove .ui-state-focus
            ui.item.children( ".formAcrdnHandle" ).triggerHandler( "focusout" );
            // Refresh accordion to handle new order
            $( this ).accordion( "refresh" );

            // Update Signup.form.fields array based on new order
            const sortedFields = [];
            $(this).children(".fieldsprops_List").each(function() {
                const field = Signup.form.fields.find(item => item.id === this.dataset.fid);
                sortedFields.push(field);
            });

            Signup.form.fields = sortedFields; // Assign the new sorted array
            const APO_custDetail = document.querySelector('.APO_custDetail'); // element where update Handlebar template
            const template = document.getElementById(`template_SignupFormField`).innerHTML; // Handlebar template
            await updateHandlebarsTemp(APO_custDetail, template, {form: Signup.form}); // Compile Handlebars template method 
            $(".DropwithSearch").customSelect({search: true}); // Initialize custom select
        }
    });
}

window.SignFprop_value = (event, element) => {
    const { Signup } = templateJSON.StyleObj;
    const fid = element.dataset.fid;
    const field = Signup.form.fields.find(field => field.id === fid);
    
    if (field) {
        const filedElement = builderData.editWidget.querySelector(`[data-fid="${fid}"]`);
        if (element.type === 'checkbox') {
            field.attributes.required = element.checked;
            filedElement.querySelector('.input-field').required = element.checked;
        } else if (element.classList.contains("__LabelVal")) {
            field.label.text = element.value;
            filedElement.querySelector('label').innerText = element.value
        } else {
            field.attributes.placeholder = element.value;
            filedElement.querySelector('input').placeholder = element.value;
        }
    }
}

window.SignFlabel_showHide = (event, element) => { 
    const { Signup } = templateJSON.StyleObj;
    const fid = element.dataset.fid;
    const field = Signup.form.fields.find(field => field.id === fid);
    field.label.show = element.checked;
    const filedElement = builderData.editWidget.querySelector(`[data-fid="${fid}"]`);
    filedElement.querySelector('label').classList[element.checked ? 'remove' : 'add']('hidden');
    document.querySelector(`.signuPropAccordion .signFprop__[data-fid="${fid}"]`).classList[element.checked ? 'remove' : 'add']('hidden');
}

// document.querySelectorAll('.input-field').forEach(input => {
//     input.addEventListener('input', event => {
//         const id = event.target.getAttribute('data-fid');
//         const value = event.target.value;
//         const field = Signup.form.fields.find(field => field.id === id);

//         if (field) {
//             if (event.target.placeholder.includes('label')) {
//                 field.label.text = value;
//             } else if (event.target.placeholder.includes('placeholder')) {
//                 field.attributes.placeholder = value;
//             }
//         }
//     });
// });