import { GetAPI_deleteAPI_method } from "./Api";
import { mouserOver_method, updateHandlebarsTemp } from "./CommonMethod";
import { filterBookingRule } from "./TemplateSettings";

export const bookedAppointmentsList = async () => {
  // Select the booking wrapper element
  const BookingWrapper = document.querySelector(".Booking .BK-wrapper");

  // Get the placeholder template for loading state
  const placeholderTemplate = document.getElementById(
    "template_bookingPlaceholderLoader"
  ).innerHTML;

  // Show the placeholder while fetching data 
  await updateHandlebarsTemp(BookingWrapper, placeholderTemplate, "");
  try {
    // Fetch appointment data from API
    const response = await GetAPI_deleteAPI_method("Appointments", "GET");

    // Filter booking rules to get cancellation and rescheduling rules
    const allowedKeys = [
      "IsAllowOnlineRescheduling",
      "IsAllowOnlineCancellation",
    ];
    const rule_CancelResdul = await filterBookingRule(allowedKeys);

    // Get the template for booked appointment list
    const template = document.getElementById(
      "template_bookedAppointmentList"
    ).innerHTML;

    // Prepare data for the template
    const templateData = {
      BookingData: response.data,
      rule_CancelResdul,
    };

    // Update the booking wrapper with the new content
    await updateHandlebarsTemp(BookingWrapper, template, templateData);

    // Attach mouseover event handlers to show hover effects
    mouserOver_method();
  } catch (error) {
    console.error("Failed to fetch booked appointments:", error);
    // Handle error appropriately (e.g., show an error message)
  }
};
