import "jquery-ui/ui/widgets/slider";
import "spectrum-colorpicker2";
import { builderData, templateJSON } from "./BuilderProps";
import { applyC_toElement, updateStyleObj } from "./CommonMethod";

// UI slider
$(".RowidthSlider").slider({
  range: "min",
  max: 1440,
  min: 600,
  value: 1280,
  create: function (event, ui) {
    var initialValue = $(this).slider("value");
    event.target.nextElementSibling.querySelector("input").value = initialValue;
  },
  slide: function (event, ui) {
    event.target.nextElementSibling.querySelector("input").value = ui.value;
    const rowContent = builderData.mainCtnr[0].querySelectorAll(".row-content");
    rowContent.forEach((items) => {
      items.style.maxWidth = ui.value + "px";
    });
  },
});

// UI slider
$(".widthSlider").slider({
  range: "min",
  max: 100,
  value: 100,
  create: function (event, ui) {
    var initialValue = $(this).slider("value");
    event.target.nextElementSibling.querySelector("input").value = initialValue;
  },
  slide: function (event, ui) {
    event.target.nextElementSibling.querySelector("input").value = ui.value;

    const WidthStyle = builderData.editWidget.querySelector(".WidthStyle");
    WidthStyle.style.width = ui.value + "%";
  },
});

// colorPicker
const colorPickerContainer = document.querySelectorAll(".Rowcolorpicker");
colorPickerContainer.forEach((items) => {
  $(items).spectrum({
    type: "text",
    showPalette: false,
    showButtons: false,
    move: function (color) {
      const settingType = this.classList;
      let applyColor = "";
      if (settingType.contains("temSetting")) {
        const mainC = builderData.mainCtnr;
        applyColor = settingType.contains("templateBG") ? mainC : mainC[0].querySelectorAll(".row-content");
      } else {
        const editWidget = document.querySelectorAll("#" + builderData.editWidget.id);
        applyColor = settingType.contains("templateBG") ? editWidget : editWidget[0].querySelectorAll(".row-content");
      }
      applyC_toElement(applyColor, color.toRgbString());
      this.parentElement.previousElementSibling.style.backgroundColor = color.toRgbString();
    },
  });
});

//init color picker
export const textBGColorInit = (className) => {
  $("." + className).spectrum({
    type: "text",
    showPalette: false,
    showButtons: false,
    move: function (color) {
      const WName = builderData.widgetName;
      const {applyto, propname} = this.dataset;
      const applyToElements = builderData.editWidget.querySelectorAll(`.${applyto}`);

      applyToElements.forEach(element => {
        (!!templateJSON.StyleObj[WName]) ? updateStyleObj(this, WName, color.toRgbString()) : '';
        //if (applyto == "active" && element.classList.contains('active')) {
          if (WName == 'Calendar') {
            element.style.setProperty('--date-seleced-color', color.toRgbString());
          } 
          else if (applyto !== "active"){
            element.style[propname] = color.toRgbString();
          }
        //}
       // else if (!element.classList.contains('active')) {
          //element.style[propname] = color.toRgbString();
        //}
        //CreateStyleObj(builderData.editWidget);
      });

      if (applyto === "active") {
        const activeStyle = builderData.editWidget.querySelector(".activeStyle" + WName);
        updateActiveStyle(color.toRgbString(), activeStyle)
      }
      this.parentElement.previousElementSibling.style.backgroundColor = color.toRgbString();
    },
    hide: function (color) {
      //const applyto = this.dataset.applyto;
      //const WName = builderData.widgetName;
      //if (applyto == "active") {
        //removeActiveStyle(builderData.editWidget.querySelector(".activeStyle" + WName)); // remove <Style> element for active
        
      //}
    },
  });

  // update/add  <Style> element for active
  const updateActiveStyle = (color, activeStyle) => {
    (!!activeStyle) ?  activeStyle.remove() : '';

    const WName = builderData.widgetName;
    var styleElement = document.createElement("style");
    styleElement.classList.add("activeStyle" + WName);
    // Define your CSS rules
    var cssRules = WName == 'Calendar' ? `.${WName} td.active:after, .${WName} td:hover:after {background-color: ${color}!important;}` : WName == 'TabWidget' ? `.${WName} li.active{color:${color}!important}` : `.${WName} .active, .nav-link:hover {color: ${color}!important;}`;
    // Append the CSS rules to the style element
    styleElement.appendChild(document.createTextNode(cssRules));
    builderData.editWidget.appendChild(styleElement);
  }
};

// const CreateStyleObj = (obj, color) => {
//  let  builderData ={ 
//   ...obj
//  };


//  return builderData

// }
