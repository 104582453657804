import { builderData } from "./BuilderProps";
import { fontFoption_Html } from "./CommonMethod";
import { bookedAppointmentsList } from "./BookedAppointmentsList";
import { initializeReviews } from "./Review";

(async function () {
  const options = await fontFoption_Html("Poppins");
  document.querySelector(".defaultFontFm").innerHTML = options;
  $(".defaultFontFm").customSelect({
    hideCallback: function () {
      var $value = $(this).find('[class*="value"]');
      builderData.mainCtnr[0].style.fontFamily = $value.text();
    },
  });
})();

// Row BG enable
window.BGimgChecks = (ele) => {
  ele.addEventListener("change", function (e) {
    const imageSelector = this.parentElement.parentElement.nextElementSibling;
    //const imageSelector = document.querySelectorAll(".image-selector");
    imageSelector.classList[this.checked ? "remove" : "add"]("hidden");

    // reset input field
    const nextElement = this.parentElement.parentElement.nextElementSibling;
    for (const input of nextElement.getElementsByTagName("input")) {
      input.type == "checkbox" ? (input.checked = this.checked) : (input.value = "");
    }

    // reset template BG
    builderData.mainCtnr[0].style.backgroundImage = "none";
    
  });
};

const rowBbackgroundSetting = (propName, prop, whereApply, element) => {
  if(whereApply === "rowBG") {
    element.style[propName] = prop;
  }
  else {
    element.querySelector(".row-content").style[propName] = prop;
  }
}

// Row BG image cover
window.BGcover = (ele) => {
  ele.addEventListener("change", function (e) {
    const {editWidget} = builderData
    const moduleName = editWidget.dataset.title;
    const BGSize = this.checked ? "cover" : "auto";
    if(moduleName == "Row") {
      const selectedToggleButton = document.querySelector(".rowBG-wrapper .ToggleButton.selected");
      const whereApply = selectedToggleButton.dataset.apply;
      rowBbackgroundSetting(backgroundSize, BGSize, whereApply, editWidget);
    }
    else {
      builderData.mainCtnr[0].style.backgroundSize = BGSize;
    }

    // Disable or enable the repeat & center checkboxes based on the checked state of BGcover
    const grandparent = this.parentElement.parentElement;
    for (const checkbox of grandparent.getElementsByTagName("input")) {
      if (checkbox !== this) {
        checkbox.disabled = this.checked ? true : false;
        checkbox.checked = false;
        builderData.mainCtnr[0].style.backgroundRepeat = "no-repeat";
        builderData.mainCtnr[0].style.backgroundPosition = "left top";
      }
    }
  });
};

// Row BG repeat and position
window.BGreptPos = (element, propertyName) => {
  element.addEventListener("change", (event) => {
    const { editWidget } = builderData;
    const moduleName = editWidget.dataset.title;

    // Determine the property value based on the checkbox state
    const propertyValue = propertyName === "backgroundRepeat"
      ? (element.checked ? "repeat" : "no-repeat")
      : (element.checked ? "center center" : "left top");

    if (moduleName === "Row") {
      const selectedToggleButton = document.querySelector(".rowBG-wrapper .ToggleButton.selected");

      if (selectedToggleButton) {
        const whereApply = selectedToggleButton.dataset.apply;
        // Apply row background settings based on the whereApply value
        rowBbackgroundSetting(propertyName, propertyValue, whereApply, editWidget);
      }
    } else if (builderData.mainCtnr[0]) {
      // Apply style to the main container if it exists
      builderData.mainCtnr[0].style[propertyName] = propertyValue;
    }
  });
};

window.applyBGTO = (ele) => {
  const ToggleButton = document.querySelectorAll(".rowBG-wrapper .ToggleButton");
  ToggleButton.forEach((btn) => btn.classList.remove("selected")); // remove selected 
  ele.classList.add("selected"); // add selected

  const whereApply = ele.dataset.apply;
  const imgURL = ele.parentElement.parentElement.nextElementSibling.querySelector(".upImageURL").value;
  if (imgURL !== "") {
    builderData.editWidget.style.backgroundImage = whereApply === "rowBG" ? `url(${imgURL})` : "none";
    builderData.editWidget.querySelector(".row-content").style.backgroundImage = whereApply === "rowBG" ? "none" : `url(${imgURL})`;
  }
};

$(".switchPage-dropdown").customSelect({
  hideCallback: async function () {
    var value = $(".switchPage-dropdown").val();
    const AllPages = document.querySelectorAll(".page-wrapper");
    for (const page of AllPages) {
      const thisPage = page.classList.contains(value);
      page.classList[thisPage ? "remove" : "add"]("hidden");
      if (page.classList.contains("Navbar") || page.classList.contains("Footer-section")) {
        page.classList.remove("hidden");
      }
    }
    if (value == "Bookingpage") {
      // await bookedAppointmentsList();
    } else if (value == "Reviewpage") {
      initializeReviews();
    }
  },
});

// action perform on tab click's
window.tabclickrouter = async (event, element) => {
  const navigoLink = element.dataset.navigo;
  const path = navigoLink.split("#");
  if (path[1] === "/bookings/type/upcoming") {
    // await bookedAppointmentsList();
  } else if (path[1] === "/review") {
    initializeReviews();
  }
};
