import { builderData, templateJSON } from "./BuilderProps";
import { GetAPI_deleteAPI_method } from "./Api";
import {
  base64DecodeUnicode_HTML,
  getUrlParams,
  mouserOver_method
} from "./CommonMethod";
import { OnboardingData } from "./OnboardingData";
import { initDragDropMethod } from "./DragDrop";
import { themeTypeSetting } from "./TemplateSettings";

(async function () {
  // Call the function
  handleThemeLoading();
})();

async function handleThemeLoading() {
  try {
    const themeId = await getUrlParams("id");
    const themeType = await getUrlParams("ThemeType") === "0" ? "ThemeById" : "MyThemeById";
    const apiUrl = `${themeType}?ThemeId=${themeId}`; 
    
    const response = await GetAPI_deleteAPI_method(apiUrl, "GET");
    const { data } = response;
    
    // Insert HTML and apply styles
    const insertData = document.getElementById("insertData");
    insertData.innerHTML = base64DecodeUnicode_HTML(data.html);
    Object.assign(templateJSON, JSON.parse(data.styleJsonMetaData));

    // Collect all main container elements
    document.querySelectorAll(".mainContainer").forEach((ele) => {
      builderData.mainCtnr.push(ele);
    });

    // Additional initialization methods
    await themeTypeSetting()
    await mouserOver_method(); // Show class on hover
    await OnboardingData(); // Load onboarding data
    initDragDropMethod(); // Initialize drag and drop

    document.querySelector(".topbar-save").disabled = false;
    document.querySelector(".topbar-preview").disabled = false;

  } catch (error) {
    console.error("Error loading theme:", error);
    // You may want to handle the error more gracefully here
  }
}

