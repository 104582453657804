import { textBGColorInit } from "./ThirdPartyLibraries";
import { fontFoption_Html } from "./CommonMethod";

export const bgImageField = (condition, widgetType) => { 
  let selectImage_prop = `<div class="input-wrapper onFly">
        <div class="flex justify-between items-center">
            <label class="property-label m-0">${condition.label.text}</label>
            ${
              condition.label.ischeckbox
                ? `<div class="custom-checkRadio switch-checbox relative flex">
                <input type="checkbox" role="switch" class="BGimgCheck" onclick="BGimgChecks(this)" checked />
                <span class="checkmark-slider"></span></div>`
                : ""
            }
            </div>
            <div class="image-selector flex flex-col gap-3">
                <div class="upload-btn-wrapper">
                    <button type="button"
                        class="btn-primary btn-upload flex gap-2 items-center justify-center w-full hover:bg-primary-200">
                        <i class="fa-solid fa-upload"></i>Upload
                    </button>
                    <input type="file" class="uploadImage" data-apply="${condition.urlType}" onchange="uploadImageAWS(event)" />
                </div>
                ${
                  condition.applyTO
                    ? `<div class="flex justify-between items-center">
                    <label class="property-label m-0">Apply image to</label>
                    <div class="rowBG-wrapper flex">
                        <button type="button" data-apply="content" class="ToggleButton rounded-e-none" onclick="applyBGTO(this)">Content</button>
                        <button type="button" data-apply="rowBG" class="ToggleButton rounded-s-none border-l-0 selected" onclick="applyBGTO(this)">Row</button>
                    </div></div>`
                    : ""
                }
                    <div class="input-container flex items-center">
                        <i class="fa-solid fa-link icon rounded-e-none"></i>
                        <input class="input-field upImageURL rounded-s-none" type="text" data-apply="${ condition.urlType }" placeholder="Enter Url" />
                    </div>
                    ${
                      condition.urlType !== "widgetSRC"
                        ? `<div class="flex gap-5 justify-between">
                        <div class="custom-checkRadio switch-checbox relative flex gap-2">
                            <input type="checkbox" role="switch" class="BGcover" checked onclick="BGcover(this)" />
                            <span class="checkmark-slider"></span>
                            <label class="property-label m-0">Cover</label>
                        </div>
                        <div
                            class="custom-checkRadio switch-checbox relative flex gap-2">
                            <input type="checkbox" class="BGrepeat" onclick="BGreptPos(this, 'backgroundRepeat')" />
                            <label class="property-label m-0">Repeat</label>
                        </div>
                        <div
                            class="custom-checkRadio switch-checbox relative flex gap-2">
                            <input type="checkbox" class="BGposition" onclick="BGreptPos(this, 'backgroundPosition')" />
                            <label class="property-label m-0">Center</label>
                        </div>
                    </div>`
                        : ""
                    }
                </div>
            </div>`;
  const whereInsert = document.querySelector(condition.whereInsert);
  whereInsert.insertAdjacentHTML("beforeend", selectImage_prop);
};

export const AddAccordion = async (options, textStyleProps) => {
  const tabExists = options.some(option => option.tabs); // Check if there are any tabs
  
  const generateAccordionHtml = async (option) => {
    const { title, tabs, backgroundColor, border, borderRadius, Typography, textInput, whereInsert } = option;

    let contentHtml = '';
    if (tabs && tabs.length) {
      const tabHtml = tabs.map(tab => `
        <li class="w-full">
          <a href="#fieldprop-${tab.text}" class="flex items-center justify-center gap-2">
            <i class="fa-solid ${tab.icon}"></i>
            <span>${tab.text}</span>
          </a>
        </li>
      `).join('');

      const tabContentHtml = await Promise.all(tabs.map(async (tab) => `
        <div id="fieldprop-${tab.text}" class="plainTab-wrapper">
          <div class="plainTab-content flex flex-col gap-4">
            ${Typography ? await TypoFieldHtml(Typography, tab) : ''}
          </div>
        </div>
      `));

      contentHtml = `
        <div class="fieldprop-Tabs plainTab">
          <ul class="flex gap-2 bg-transparent border-0">
            ${tabHtml}
          </ul>
          ${tabContentHtml.join('')}
        </div>
      `;
    } else {
      const backgroundColorHtml = backgroundColor ? await Promise.all(backgroundColor.map(async (props) => {
          return `<div class="input-wrapper">${await TextColorField(props, props.BGColorProp)}</div>`;
      })) : '';
      
      contentHtml = `<div class="flex flex-col gap-4">
        ${textInput ? await textInputfield(textInput) : ''}
        ${(!!backgroundColorHtml) ? backgroundColorHtml.join('') : '' }
        ${border ? await BorderField(border, textStyleProps) : ''}
        ${borderRadius ? `<div class="input-wrapper flex justify-between items-center label-m-0">${await NumberFieldHtml(borderRadius, textStyleProps.Stylejson["borderRadius"])}</div>` : ''}
        ${Typography ? await TypoFieldHtml(Typography, textStyleProps) : ''}
        </div>`;
    }

    return `
      <div class="custom-accordion accordion-setting propTypography onFly">
        <h3 class="setting-title">${title}</h3>
        <div class="setting-panel">
          ${contentHtml}
        </div>
      </div>
    `;
  };

  const insertAccordions = async (option) => {
    const accordionHtml = await generateAccordionHtml(option);
    const whereInsert = document.querySelector(option.whereInsert);
    whereInsert.insertAdjacentHTML("beforeend", accordionHtml);
  };

  for(const option of options) {
    await insertAccordions(option)
  };

  textBGColorInit("colorfield"); // Initialize color picker
  if (tabExists) $(".fieldprop-Tabs").tabs(); // Initialize tabs if they exist
  $(".custmSelect").customSelect(); // Initialize custom select
  
  $(".custom-accordion").accordion({collapsible: true}); // Initialize accordion
};

// export const TypographyStyle = async (JSON, Txtsyleprop) => {
//   const condition = JSON.filed.find((obj) => obj["Typography"]).Typography;

//   const TypoAccordion = `<div class="custom-accordion accordion-setting propTypography onFly">
//       <h3 class="setting-title">${JSON.title}</h3>
//       <div class="setting-panel">
//       ${
//         !!JSON.tabs
//           ? `<div class="fieldprop-Tabs plainTab">
//             <ul class="flex gap-2 bg-transparent border-0">
//               ${JSON.tabs
//                 .map(
//                   (items, i) =>
//                     `<li class="w-full"><a href="#fieldprop-${items.text}" class="flex items-center justify-center gap-2"><i class="fa-solid ${items.icon}"></i><span>${items.text}</span></a></li>`
//                 )
//                 .join("")}
//             </ul>
//           ${await Promise.all(
//             JSON.tabs.map(
//               async (items, i) =>
//                 `<div id="fieldprop-${
//                   items.text
//                 }" class="plainTab-wrapper"><div class="plainTab-contant">${await TypofiledHtml(
//                   condition,
//                   items.Txtsylejson
//                 )}</div></div>`
//             )
//           ).then((htmlArray) => htmlArray.join(""))}
//         </div>`
//           : await TypofiledHtml(condition, Txtsyleprop)
//       }
//       </div></div>`;

//   const whereInsert = document.querySelector(JSON.whereInsert);
//   whereInsert.insertAdjacentHTML("beforeend", TypoAccordion);
//   textBGColorInit("colorfield"); //init color picker
//   !!JSON.tabs && $(".fieldprop-Tabs").tabs();
//   $(".custmSelect").customSelect();
//   $(".custom-accordion").accordion();
// };

const TypoFieldHtml = async (condition, Txtsyleprop) => {
  const {fontFamily, fontSize, fontWeight, letterSpacing, lineHeight, color} = Txtsyleprop.Stylejson
  const FontSprop = {
    label: "Font size",
    type: "fontSize",
    applyTO: Txtsyleprop.applyTO,
    border: "rounded-none border-x-0",
    infobox: true,
    width: "64px",
  };
  const FontWprop = {
    label: "Font weight",
    type: "fontWeight",
    applyTO: Txtsyleprop.applyTO,
    options: [
      { value: 500, name: "Semi-bold" },
      { value: 400, name: "Normal" },
      { value: 700, name: "Bold" },
    ]
  }
  const LineHprop = {
    label: "Line height",
    applyTO: Txtsyleprop.applyTO,
    width: "100%",
  };
  const letterSprop = {
    label: "Letter space",
    type: "letterSpacing",
    applyTO: Txtsyleprop.applyTO,
    border: "rounded-e-none border-r-0",
    infobox: true,
    width: "100%",
  };
  const Textcolor = {
    type: "color",
    applyTO: Txtsyleprop.applyTO,
    label: "Text Color",
  };
  const ActiveTextcolor = {
    type: "color",
    applyTO: "active",
    label: "Active text color",
  };

  const filedHtml = `<div class="flex flex-col gap-4">
      <div class="input-wrapper flex">
        <div class="w-full">${await fontFfiledHtml(Txtsyleprop.applyTO, fontFamily)}</div>
        <div>
          ${await NumberFieldHtml(FontSprop, fontSize)}
        </div>
      </div>
      <div class="input-wrapper flex gap-3">
        <div class="w-full">
          ${await selectFieldHtml(FontWprop, fontWeight)}
        </div>
        <div class="w-full">
        ${
          condition.isLineH
            ? lineHfiledHtml(LineHprop, lineHeight)
            : `${await NumberFieldHtml(letterSprop, letterSpacing)}`
        } 
        </div>
      </div>
      <div class="input-wrapper flex gap-3">
        <div class="w-full">${await TextColorField(Textcolor, color)}</div>
        <div class="w-full">
            ${
              condition.ActiveTextcolor
                ? `${await TextColorField(ActiveTextcolor, Txtsyleprop.activecolor)}`
                : `${await NumberFieldHtml(letterSprop, letterSpacing)}`
            } 
        </div>
      </div></div>`;

  return filedHtml;
};

// basic text input
const textInputfield = async (prop) => {
  
  const html = `<div class="input-wrapper"><label class="property-label">${prop.label}</label><input onchange="updateBtnText(event, this)" type="${prop.type}" data-applyto="${prop.applyTO}" value="${prop.value}" class="input-field" /></div>`;
  return html;
}

// font weight
const selectFieldHtml = async (prop, value) => {
  const html = `${!!prop.label ? `<label class="property-label">${prop.label}</label>` : ''}<select class="propDropdown custmSelect" data-applyto="${prop.applyTO}" data-propname="${prop.type}">
      ${prop.options
        .map(
          (items) =>
            `<option value="${items.value}" ${
              items.value == value && "selected"
            }>${items.name}</option>`
        )
        .join("")}
    </select>`;
  return html;
};

// font family
const fontFfiledHtml = async (TO, value) => {
  const options = await fontFoption_Html(value);
  const html = `<label class="property-label">Font family</label>
    <div class="customS-arrowbox-0 rounded-e-none"><select class="custmSelect propDropdown" data-applyto="${TO}" data-propname="fontFamily">${options}</select></div>`;
  return html;
};

const lineHfiledHtml = (prop, value) => {
  const option = [
    {
      line: "130%",
      icon: '<path fill="currentcolor" d="M25 7.75H0v1.5h25v-1.5ZM25 11.5H0V13h25v-1.5ZM25 15.25H0v1.5h25v-1.5Z"></path>',
    },
    {
      line: "170%",
      icon: '<path fill="currentcolor" d="M25 6.5H0V8h25V6.5ZM25 11.5H0V13h25v-1.5ZM25 16.5H0V18h25v-1.5Z"></path>',
    },
    {
      line: "200%",
      icon: '<path fill="currentcolor" d="M25 4.25H0v1.5h25v-1.5ZM25 11.5H0V13h25v-1.5ZM25 18.75H0v1.5h25v-1.5Z"></path>',
    },
  ];

  const html = `<label class="property-label">${prop.label}</label><div class="boxedicon-wrapper flex">
  ${option
    .map(
      (items, i) =>
        `<button type="button" data-applyto="${prop.applyTO}" data-line="${
          items.line
        }" class="boxedicon flex customSVG ${
          items.line == value && "selected"
        }" onclick="propLineH(this)"><svg xmlns="http://www.w3.org/2000/svg" width="21.2" height="20" fill="none" viewBox="0 0 23 23">${
          items.icon
        }</svg></button>`
    )
    .join("")}
  </div>`;

  return html;
};

export const TextColorField = async (prop, value) => {
  const html = `${!!prop.label ? `<label class="property-label">${prop.label}</label>` : ''}<div class="color-selector flex items-center">
                  <span class="InputColor_preview" style="background-color: ${value};"></span>
                  <input type="text" data-applyto="${prop.applyTO}" data-propname="${prop.type}" value="${value}" class="input-field colorpicker colorfield spectrum sp-colorize" /></div>`;
  return html;
};

const BorderField = async (prop, Style) => {
  const {borderStyle, borderWidth, borderColor} = Style.Stylejson
  const borderWprop = {
    applyTO: prop.applyTO,
    infobox: false,
    width: "100%",
    type: "borderWidth"
};
const borderSprop = {
  applyTO: prop.applyTO,
  type: "borderStyle",
  options: [
      { value: 'solid', name: "Solid" },
      { value: 'dotted', name: "Dotted" },
      { value: 'dashed', name: "Dashed" },
    ]
};
const borderCprop = {
  applyTO: prop.applyTO,
  type: "borderColor",
};
  const html = `<div class="input-wrapper">
      <label class="property-label">${prop.label}</label>
      <div class="borderF-wrapper">
        <div class="borderF-row flex justify-between items-center gap-3">
          <label class="property-label" style="margin-bottom: 0px;">All side</label>
            <div class="borderF-filed flex">
               <div style="width: 93px;" class="customS-arrowbox-0 rounded-e-none">${await selectFieldHtml(borderSprop, borderStyle)}</div>
               <div style="width: 52px;">${await NumberFieldHtml(borderWprop, borderWidth)}</div>
               <div style="width: 130px;">${await TextColorField(borderCprop, borderColor)}</div>
            </div>
        </div>
      </div>
  </div>`;
  return html;
}

// number filed
const NumberFieldHtml = async (prop, value) => {
  const html = `${!!prop.label ? `<label class="property-label">${prop.label}</label>` : ''}<div class="flex items-center numberF-prop">
          <div class="relative" style="width: ${prop.width}">
              <input type="number" value="${value.replace(/[px%]/g, "")}" data-applyto="${prop.applyTO}" data-propname="${prop.type}" class="input-field number-field ${prop.border} text-center" />
              <div class="number-plusminus flex flex-col items-center">
                  <button type="button" class="btn-sub flex items-end"><i class="fa-solid fa-angle-up h-2.5 w-2.5"></i></button>
                  <button type="button" class="btn-plus flex items-start"><i class="fa-solid fa-angle-down h-2.5 w-2.5"></i></button>
              </div>
          </div>
          ${(prop.infobox) ? `<span type="text" class="input-infobox bg-gray-50">PX</span>` : ''}
          
      </div>`;
  return html;
};
