import { APOData, templateJSON } from "./BuilderProps";
import { updateHandlebarsTemp } from "./CommonMethod";
import { get__ServiceEventClass } from "./Service";

(async function () {
  // $(".S_customSelect").customSelect();

  // service layout filter
  $(".serviceLayoutFilter").change(async function () {
    templateJSON.StyleObj["Service"].layout = this.value; // set service type globally
    const { APOtype } = APOData;
    await appointmentLayout(APOData[APOtype]);
  });

})();

window.filterAppointment = async (event) => {
  const value = event.target.value;
  // Set service type and APO type globally
  templateJSON.StyleObj["Service"].APOtype = value;
  APOData.APOtype = value;

  //try {
    await appointmentLayout();
  //} catch (error) {
    //console.error("Error in filterAppointment:", error.message);
  //}
};

export const appointmentLayout = async (result) => {
  // Fetch appointment data based on the selected value
  const data = result ? result :  await get__ServiceEventClass();
  //if (data.length) {
    const { APOtype, appointmentFilter } = APOData;

    const appendElement = document.querySelector(`.Service .APO_booking_content`);
    const template = document.getElementById(`template_service${templateJSON.StyleObj["Service"].layout}`).innerHTML;

    const templateData = {
      appointmentData: data,
      APOtypeName: APOtype + (APOtype === "Class" ? "es" : "s"),
      appointmentFilter,
      IsBookAppointmentVisible: true
    }

    await updateHandlebarsTemp(appendElement, template, templateData); // Compile Handlebars template method

    $(".S_customSelect").customSelect();
  //}

  // display team list
  displayTeamList();
};

const displayTeamList = async () => {
  const { onboardingData } = APOData;

  const appendElement = document.querySelector(`.TeamList .team-wrapper`);
  const template = document.getElementById(`template_teamList`).innerHTML;

  await updateHandlebarsTemp(appendElement, template, { 
    teamList: onboardingData.staffMembersList,
  }); // Compile Handlebars template method

}

export const appointmentTypeList = async (data) => {
  try {
    // Update appointment filter based on provided conditions
    const APOtypeList = [
      { condition: data.isServiceAdded, typeName: "Service", displayName: "Service"},
      { condition: data.isClassAdded, typeName: "Class", displayName: "Class" },
      { condition: data.isEventAdded, typeName: "Event", displayName: "Event" },
      { condition: data.IsGlobalEventAdded, typeName: "GlobalEvents", displayName: "Event planning" },
    ];

    // Apply the filtered results
    APOData.appointmentFilter = APOtypeList.filter((item) => item.condition);
    APOData.APOtype = APOData.appointmentFilter[0].typeName;

  } catch (error) {
    console.error("Error setting appointment types:", error);
  }
}
